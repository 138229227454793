:root {
  --background-color: #ffffff;
  --primary-color: #16c197;
  --secondary-color: #0e46a3;
  --text-primary-color: #555555;
  --text-secondary-color: #1e0342;
  --code-bg-color: #555555; /* Example for code background */
}

[data-theme='dark'] {
  --background-color: #0f172a;
  --primary-color: #ff71cd;
  --secondary-color: #114ee9;
  --text-primary-color: #16c197;
  --text-secondary-color: #37b7c3;
  --code-bg-color: #0f122c;
}

body {
  max-height: 100vh;
  width: 100vw;
  margin: 0;
  background-color: var(--background-color);
  color: var(--text-primary-color);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

code {
  background-color: var(--code-bg-color);
  color: var(--primary-color);
}

.content code:not(pre code) {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  background-color: var(--code-bg-color);
  color: var(--primary-color);
  border-radius: 0.5em;
  padding: 0.2em 0.4em;
}

.content table {
  border-spacing: 0 !important;
  border-collapse: collapse !important;
  border-color: inherit !important;
  display: block !important;
  margin: 0 auto !important;
  width: 100% !important;
  max-width: 100% !important;
  overflow: auto !important;
  margin: 1rem;
}

.content tbody,
.content td,
.content tfoot,
.content th,
.content thead,
.content tr {
  border-color: inherit !important;
  border-style: solid !important;
  border-width: 2px !important;
  padding: 0.5rem;
}

.content ul.contains-task-list {
  list-style-type: none;
}
